@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
 * v2.0 | 20110126
 * License: none (public domain)
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Bootstrap
 */
/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col, #sticky-nav .container-fluid .row .search .field .no-gutters > input,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col-25, .col-26, .col-27, .col-28, .col-29, .col-30, .col-31, .col-32, .col-33, .col-34, .col-35, .col-36, .col-37, .col-38, .col-39, .col-40, .col-41, .col-42, .col-43, .col-44, .col-45, .col-46, .col-47, .col-48, .col-49, .col-50, .col-51, .col-52, .col-53, .col-54, .col-55, .col-56, .col-57, .col-58, .col-59, .col-60, .col, #sticky-nav .container-fluid .row .search .field input,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm-25, .col-sm-26, .col-sm-27, .col-sm-28, .col-sm-29, .col-sm-30, .col-sm-31, .col-sm-32, .col-sm-33, .col-sm-34, .col-sm-35, .col-sm-36, .col-sm-37, .col-sm-38, .col-sm-39, .col-sm-40, .col-sm-41, .col-sm-42, .col-sm-43, .col-sm-44, .col-sm-45, .col-sm-46, .col-sm-47, .col-sm-48, .col-sm-49, .col-sm-50, .col-sm-51, .col-sm-52, .col-sm-53, .col-sm-54, .col-sm-55, .col-sm-56, .col-sm-57, .col-sm-58, .col-sm-59, .col-sm-60, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md-25, .col-md-26, .col-md-27, .col-md-28, .col-md-29, .col-md-30, .col-md-31, .col-md-32, .col-md-33, .col-md-34, .col-md-35, .col-md-36, .col-md-37, .col-md-38, .col-md-39, .col-md-40, .col-md-41, .col-md-42, .col-md-43, .col-md-44, .col-md-45, .col-md-46, .col-md-47, .col-md-48, .col-md-49, .col-md-50, .col-md-51, .col-md-52, .col-md-53, .col-md-54, .col-md-55, .col-md-56, .col-md-57, .col-md-58, .col-md-59, .col-md-60, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg-25, .col-lg-26, .col-lg-27, .col-lg-28, .col-lg-29, .col-lg-30, .col-lg-31, .col-lg-32, .col-lg-33, .col-lg-34, .col-lg-35, .col-lg-36, .col-lg-37, .col-lg-38, .col-lg-39, .col-lg-40, .col-lg-41, .col-lg-42, .col-lg-43, .col-lg-44, .col-lg-45, .col-lg-46, .col-lg-47, .col-lg-48, .col-lg-49, .col-lg-50, .col-lg-51, .col-lg-52, .col-lg-53, .col-lg-54, .col-lg-55, .col-lg-56, .col-lg-57, .col-lg-58, .col-lg-59, .col-lg-60, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl-25, .col-xl-26, .col-xl-27, .col-xl-28, .col-xl-29, .col-xl-30, .col-xl-31, .col-xl-32, .col-xl-33, .col-xl-34, .col-xl-35, .col-xl-36, .col-xl-37, .col-xl-38, .col-xl-39, .col-xl-40, .col-xl-41, .col-xl-42, .col-xl-43, .col-xl-44, .col-xl-45, .col-xl-46, .col-xl-47, .col-xl-48, .col-xl-49, .col-xl-50, .col-xl-51, .col-xl-52, .col-xl-53, .col-xl-54, .col-xl-55, .col-xl-56, .col-xl-57, .col-xl-58, .col-xl-59, .col-xl-60, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl-25, .col-xxl-26, .col-xxl-27, .col-xxl-28, .col-xxl-29, .col-xxl-30, .col-xxl-31, .col-xxl-32, .col-xxl-33, .col-xxl-34, .col-xxl-35, .col-xxl-36, .col-xxl-37, .col-xxl-38, .col-xxl-39, .col-xxl-40, .col-xxl-41, .col-xxl-42, .col-xxl-43, .col-xxl-44, .col-xxl-45, .col-xxl-46, .col-xxl-47, .col-xxl-48, .col-xxl-49, .col-xxl-50, .col-xxl-51, .col-xxl-52, .col-xxl-53, .col-xxl-54, .col-xxl-55, .col-xxl-56, .col-xxl-57, .col-xxl-58, .col-xxl-59, .col-xxl-60, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px; }

.col, #sticky-nav .container-fluid .row .search .field input {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 1.6666666667%;
  max-width: 1.6666666667%; }

.col-2 {
  flex: 0 0 3.3333333333%;
  max-width: 3.3333333333%; }

.col-3 {
  flex: 0 0 5%;
  max-width: 5%; }

.col-4 {
  flex: 0 0 6.6666666667%;
  max-width: 6.6666666667%; }

.col-5 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-6 {
  flex: 0 0 10%;
  max-width: 10%; }

.col-7 {
  flex: 0 0 11.6666666667%;
  max-width: 11.6666666667%; }

.col-8 {
  flex: 0 0 13.3333333333%;
  max-width: 13.3333333333%; }

.col-9 {
  flex: 0 0 15%;
  max-width: 15%; }

.col-10 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-11 {
  flex: 0 0 18.3333333333%;
  max-width: 18.3333333333%; }

.col-12 {
  flex: 0 0 20%;
  max-width: 20%; }

.col-13 {
  flex: 0 0 21.6666666667%;
  max-width: 21.6666666667%; }

.col-14 {
  flex: 0 0 23.3333333333%;
  max-width: 23.3333333333%; }

.col-15 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-16 {
  flex: 0 0 26.6666666667%;
  max-width: 26.6666666667%; }

.col-17 {
  flex: 0 0 28.3333333333%;
  max-width: 28.3333333333%; }

.col-18 {
  flex: 0 0 30%;
  max-width: 30%; }

.col-19 {
  flex: 0 0 31.6666666667%;
  max-width: 31.6666666667%; }

.col-20 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-21 {
  flex: 0 0 35%;
  max-width: 35%; }

.col-22 {
  flex: 0 0 36.6666666667%;
  max-width: 36.6666666667%; }

.col-23 {
  flex: 0 0 38.3333333333%;
  max-width: 38.3333333333%; }

.col-24 {
  flex: 0 0 40%;
  max-width: 40%; }

.col-25 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-26 {
  flex: 0 0 43.3333333333%;
  max-width: 43.3333333333%; }

.col-27 {
  flex: 0 0 45%;
  max-width: 45%; }

.col-28 {
  flex: 0 0 46.6666666667%;
  max-width: 46.6666666667%; }

.col-29 {
  flex: 0 0 48.3333333333%;
  max-width: 48.3333333333%; }

.col-30 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-31 {
  flex: 0 0 51.6666666667%;
  max-width: 51.6666666667%; }

.col-32 {
  flex: 0 0 53.3333333333%;
  max-width: 53.3333333333%; }

.col-33 {
  flex: 0 0 55%;
  max-width: 55%; }

.col-34 {
  flex: 0 0 56.6666666667%;
  max-width: 56.6666666667%; }

.col-35 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-36 {
  flex: 0 0 60%;
  max-width: 60%; }

.col-37 {
  flex: 0 0 61.6666666667%;
  max-width: 61.6666666667%; }

.col-38 {
  flex: 0 0 63.3333333333%;
  max-width: 63.3333333333%; }

.col-39 {
  flex: 0 0 65%;
  max-width: 65%; }

.col-40 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-41 {
  flex: 0 0 68.3333333333%;
  max-width: 68.3333333333%; }

.col-42 {
  flex: 0 0 70%;
  max-width: 70%; }

.col-43 {
  flex: 0 0 71.6666666667%;
  max-width: 71.6666666667%; }

.col-44 {
  flex: 0 0 73.3333333333%;
  max-width: 73.3333333333%; }

.col-45 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-46 {
  flex: 0 0 76.6666666667%;
  max-width: 76.6666666667%; }

.col-47 {
  flex: 0 0 78.3333333333%;
  max-width: 78.3333333333%; }

.col-48 {
  flex: 0 0 80%;
  max-width: 80%; }

.col-49 {
  flex: 0 0 81.6666666667%;
  max-width: 81.6666666667%; }

.col-50 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-51 {
  flex: 0 0 85%;
  max-width: 85%; }

.col-52 {
  flex: 0 0 86.6666666667%;
  max-width: 86.6666666667%; }

.col-53 {
  flex: 0 0 88.3333333333%;
  max-width: 88.3333333333%; }

.col-54 {
  flex: 0 0 90%;
  max-width: 90%; }

.col-55 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-56 {
  flex: 0 0 93.3333333333%;
  max-width: 93.3333333333%; }

.col-57 {
  flex: 0 0 95%;
  max-width: 95%; }

.col-58 {
  flex: 0 0 96.6666666667%;
  max-width: 96.6666666667%; }

.col-59 {
  flex: 0 0 98.3333333333%;
  max-width: 98.3333333333%; }

.col-60 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 61; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.order-25 {
  order: 25; }

.order-26 {
  order: 26; }

.order-27 {
  order: 27; }

.order-28 {
  order: 28; }

.order-29 {
  order: 29; }

.order-30 {
  order: 30; }

.order-31 {
  order: 31; }

.order-32 {
  order: 32; }

.order-33 {
  order: 33; }

.order-34 {
  order: 34; }

.order-35 {
  order: 35; }

.order-36 {
  order: 36; }

.order-37 {
  order: 37; }

.order-38 {
  order: 38; }

.order-39 {
  order: 39; }

.order-40 {
  order: 40; }

.order-41 {
  order: 41; }

.order-42 {
  order: 42; }

.order-43 {
  order: 43; }

.order-44 {
  order: 44; }

.order-45 {
  order: 45; }

.order-46 {
  order: 46; }

.order-47 {
  order: 47; }

.order-48 {
  order: 48; }

.order-49 {
  order: 49; }

.order-50 {
  order: 50; }

.order-51 {
  order: 51; }

.order-52 {
  order: 52; }

.order-53 {
  order: 53; }

.order-54 {
  order: 54; }

.order-55 {
  order: 55; }

.order-56 {
  order: 56; }

.order-57 {
  order: 57; }

.order-58 {
  order: 58; }

.order-59 {
  order: 59; }

.order-60 {
  order: 60; }

.offset-1 {
  margin-left: 1.6666666667%; }

.offset-2 {
  margin-left: 3.3333333333%; }

.offset-3 {
  margin-left: 5%; }

.offset-4 {
  margin-left: 6.6666666667%; }

.offset-5 {
  margin-left: 8.3333333333%; }

.offset-6 {
  margin-left: 10%; }

.offset-7 {
  margin-left: 11.6666666667%; }

.offset-8 {
  margin-left: 13.3333333333%; }

.offset-9 {
  margin-left: 15%; }

.offset-10 {
  margin-left: 16.6666666667%; }

.offset-11 {
  margin-left: 18.3333333333%; }

.offset-12 {
  margin-left: 20%; }

.offset-13 {
  margin-left: 21.6666666667%; }

.offset-14 {
  margin-left: 23.3333333333%; }

.offset-15 {
  margin-left: 25%; }

.offset-16 {
  margin-left: 26.6666666667%; }

.offset-17 {
  margin-left: 28.3333333333%; }

.offset-18 {
  margin-left: 30%; }

.offset-19 {
  margin-left: 31.6666666667%; }

.offset-20 {
  margin-left: 33.3333333333%; }

.offset-21 {
  margin-left: 35%; }

.offset-22 {
  margin-left: 36.6666666667%; }

.offset-23 {
  margin-left: 38.3333333333%; }

.offset-24 {
  margin-left: 40%; }

.offset-25 {
  margin-left: 41.6666666667%; }

.offset-26 {
  margin-left: 43.3333333333%; }

.offset-27 {
  margin-left: 45%; }

.offset-28 {
  margin-left: 46.6666666667%; }

.offset-29 {
  margin-left: 48.3333333333%; }

.offset-30 {
  margin-left: 50%; }

.offset-31 {
  margin-left: 51.6666666667%; }

.offset-32 {
  margin-left: 53.3333333333%; }

.offset-33 {
  margin-left: 55%; }

.offset-34 {
  margin-left: 56.6666666667%; }

.offset-35 {
  margin-left: 58.3333333333%; }

.offset-36 {
  margin-left: 60%; }

.offset-37 {
  margin-left: 61.6666666667%; }

.offset-38 {
  margin-left: 63.3333333333%; }

.offset-39 {
  margin-left: 65%; }

.offset-40 {
  margin-left: 66.6666666667%; }

.offset-41 {
  margin-left: 68.3333333333%; }

.offset-42 {
  margin-left: 70%; }

.offset-43 {
  margin-left: 71.6666666667%; }

.offset-44 {
  margin-left: 73.3333333333%; }

.offset-45 {
  margin-left: 75%; }

.offset-46 {
  margin-left: 76.6666666667%; }

.offset-47 {
  margin-left: 78.3333333333%; }

.offset-48 {
  margin-left: 80%; }

.offset-49 {
  margin-left: 81.6666666667%; }

.offset-50 {
  margin-left: 83.3333333333%; }

.offset-51 {
  margin-left: 85%; }

.offset-52 {
  margin-left: 86.6666666667%; }

.offset-53 {
  margin-left: 88.3333333333%; }

.offset-54 {
  margin-left: 90%; }

.offset-55 {
  margin-left: 91.6666666667%; }

.offset-56 {
  margin-left: 93.3333333333%; }

.offset-57 {
  margin-left: 95%; }

.offset-58 {
  margin-left: 96.6666666667%; }

.offset-59 {
  margin-left: 98.3333333333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 1.6666666667%;
    max-width: 1.6666666667%; }
  .col-sm-2 {
    flex: 0 0 3.3333333333%;
    max-width: 3.3333333333%; }
  .col-sm-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-sm-4 {
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%; }
  .col-sm-5 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-sm-7 {
    flex: 0 0 11.6666666667%;
    max-width: 11.6666666667%; }
  .col-sm-8 {
    flex: 0 0 13.3333333333%;
    max-width: 13.3333333333%; }
  .col-sm-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-sm-10 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-11 {
    flex: 0 0 18.3333333333%;
    max-width: 18.3333333333%; }
  .col-sm-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-sm-13 {
    flex: 0 0 21.6666666667%;
    max-width: 21.6666666667%; }
  .col-sm-14 {
    flex: 0 0 23.3333333333%;
    max-width: 23.3333333333%; }
  .col-sm-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-16 {
    flex: 0 0 26.6666666667%;
    max-width: 26.6666666667%; }
  .col-sm-17 {
    flex: 0 0 28.3333333333%;
    max-width: 28.3333333333%; }
  .col-sm-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-sm-19 {
    flex: 0 0 31.6666666667%;
    max-width: 31.6666666667%; }
  .col-sm-20 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-sm-22 {
    flex: 0 0 36.6666666667%;
    max-width: 36.6666666667%; }
  .col-sm-23 {
    flex: 0 0 38.3333333333%;
    max-width: 38.3333333333%; }
  .col-sm-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-sm-25 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-26 {
    flex: 0 0 43.3333333333%;
    max-width: 43.3333333333%; }
  .col-sm-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-sm-28 {
    flex: 0 0 46.6666666667%;
    max-width: 46.6666666667%; }
  .col-sm-29 {
    flex: 0 0 48.3333333333%;
    max-width: 48.3333333333%; }
  .col-sm-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-31 {
    flex: 0 0 51.6666666667%;
    max-width: 51.6666666667%; }
  .col-sm-32 {
    flex: 0 0 53.3333333333%;
    max-width: 53.3333333333%; }
  .col-sm-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-sm-34 {
    flex: 0 0 56.6666666667%;
    max-width: 56.6666666667%; }
  .col-sm-35 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-sm-37 {
    flex: 0 0 61.6666666667%;
    max-width: 61.6666666667%; }
  .col-sm-38 {
    flex: 0 0 63.3333333333%;
    max-width: 63.3333333333%; }
  .col-sm-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-sm-40 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-41 {
    flex: 0 0 68.3333333333%;
    max-width: 68.3333333333%; }
  .col-sm-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-sm-43 {
    flex: 0 0 71.6666666667%;
    max-width: 71.6666666667%; }
  .col-sm-44 {
    flex: 0 0 73.3333333333%;
    max-width: 73.3333333333%; }
  .col-sm-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-46 {
    flex: 0 0 76.6666666667%;
    max-width: 76.6666666667%; }
  .col-sm-47 {
    flex: 0 0 78.3333333333%;
    max-width: 78.3333333333%; }
  .col-sm-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-sm-49 {
    flex: 0 0 81.6666666667%;
    max-width: 81.6666666667%; }
  .col-sm-50 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-sm-52 {
    flex: 0 0 86.6666666667%;
    max-width: 86.6666666667%; }
  .col-sm-53 {
    flex: 0 0 88.3333333333%;
    max-width: 88.3333333333%; }
  .col-sm-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-sm-55 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-56 {
    flex: 0 0 93.3333333333%;
    max-width: 93.3333333333%; }
  .col-sm-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-sm-58 {
    flex: 0 0 96.6666666667%;
    max-width: 96.6666666667%; }
  .col-sm-59 {
    flex: 0 0 98.3333333333%;
    max-width: 98.3333333333%; }
  .col-sm-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 61; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .order-sm-25 {
    order: 25; }
  .order-sm-26 {
    order: 26; }
  .order-sm-27 {
    order: 27; }
  .order-sm-28 {
    order: 28; }
  .order-sm-29 {
    order: 29; }
  .order-sm-30 {
    order: 30; }
  .order-sm-31 {
    order: 31; }
  .order-sm-32 {
    order: 32; }
  .order-sm-33 {
    order: 33; }
  .order-sm-34 {
    order: 34; }
  .order-sm-35 {
    order: 35; }
  .order-sm-36 {
    order: 36; }
  .order-sm-37 {
    order: 37; }
  .order-sm-38 {
    order: 38; }
  .order-sm-39 {
    order: 39; }
  .order-sm-40 {
    order: 40; }
  .order-sm-41 {
    order: 41; }
  .order-sm-42 {
    order: 42; }
  .order-sm-43 {
    order: 43; }
  .order-sm-44 {
    order: 44; }
  .order-sm-45 {
    order: 45; }
  .order-sm-46 {
    order: 46; }
  .order-sm-47 {
    order: 47; }
  .order-sm-48 {
    order: 48; }
  .order-sm-49 {
    order: 49; }
  .order-sm-50 {
    order: 50; }
  .order-sm-51 {
    order: 51; }
  .order-sm-52 {
    order: 52; }
  .order-sm-53 {
    order: 53; }
  .order-sm-54 {
    order: 54; }
  .order-sm-55 {
    order: 55; }
  .order-sm-56 {
    order: 56; }
  .order-sm-57 {
    order: 57; }
  .order-sm-58 {
    order: 58; }
  .order-sm-59 {
    order: 59; }
  .order-sm-60 {
    order: 60; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 1.6666666667%; }
  .offset-sm-2 {
    margin-left: 3.3333333333%; }
  .offset-sm-3 {
    margin-left: 5%; }
  .offset-sm-4 {
    margin-left: 6.6666666667%; }
  .offset-sm-5 {
    margin-left: 8.3333333333%; }
  .offset-sm-6 {
    margin-left: 10%; }
  .offset-sm-7 {
    margin-left: 11.6666666667%; }
  .offset-sm-8 {
    margin-left: 13.3333333333%; }
  .offset-sm-9 {
    margin-left: 15%; }
  .offset-sm-10 {
    margin-left: 16.6666666667%; }
  .offset-sm-11 {
    margin-left: 18.3333333333%; }
  .offset-sm-12 {
    margin-left: 20%; }
  .offset-sm-13 {
    margin-left: 21.6666666667%; }
  .offset-sm-14 {
    margin-left: 23.3333333333%; }
  .offset-sm-15 {
    margin-left: 25%; }
  .offset-sm-16 {
    margin-left: 26.6666666667%; }
  .offset-sm-17 {
    margin-left: 28.3333333333%; }
  .offset-sm-18 {
    margin-left: 30%; }
  .offset-sm-19 {
    margin-left: 31.6666666667%; }
  .offset-sm-20 {
    margin-left: 33.3333333333%; }
  .offset-sm-21 {
    margin-left: 35%; }
  .offset-sm-22 {
    margin-left: 36.6666666667%; }
  .offset-sm-23 {
    margin-left: 38.3333333333%; }
  .offset-sm-24 {
    margin-left: 40%; }
  .offset-sm-25 {
    margin-left: 41.6666666667%; }
  .offset-sm-26 {
    margin-left: 43.3333333333%; }
  .offset-sm-27 {
    margin-left: 45%; }
  .offset-sm-28 {
    margin-left: 46.6666666667%; }
  .offset-sm-29 {
    margin-left: 48.3333333333%; }
  .offset-sm-30 {
    margin-left: 50%; }
  .offset-sm-31 {
    margin-left: 51.6666666667%; }
  .offset-sm-32 {
    margin-left: 53.3333333333%; }
  .offset-sm-33 {
    margin-left: 55%; }
  .offset-sm-34 {
    margin-left: 56.6666666667%; }
  .offset-sm-35 {
    margin-left: 58.3333333333%; }
  .offset-sm-36 {
    margin-left: 60%; }
  .offset-sm-37 {
    margin-left: 61.6666666667%; }
  .offset-sm-38 {
    margin-left: 63.3333333333%; }
  .offset-sm-39 {
    margin-left: 65%; }
  .offset-sm-40 {
    margin-left: 66.6666666667%; }
  .offset-sm-41 {
    margin-left: 68.3333333333%; }
  .offset-sm-42 {
    margin-left: 70%; }
  .offset-sm-43 {
    margin-left: 71.6666666667%; }
  .offset-sm-44 {
    margin-left: 73.3333333333%; }
  .offset-sm-45 {
    margin-left: 75%; }
  .offset-sm-46 {
    margin-left: 76.6666666667%; }
  .offset-sm-47 {
    margin-left: 78.3333333333%; }
  .offset-sm-48 {
    margin-left: 80%; }
  .offset-sm-49 {
    margin-left: 81.6666666667%; }
  .offset-sm-50 {
    margin-left: 83.3333333333%; }
  .offset-sm-51 {
    margin-left: 85%; }
  .offset-sm-52 {
    margin-left: 86.6666666667%; }
  .offset-sm-53 {
    margin-left: 88.3333333333%; }
  .offset-sm-54 {
    margin-left: 90%; }
  .offset-sm-55 {
    margin-left: 91.6666666667%; }
  .offset-sm-56 {
    margin-left: 93.3333333333%; }
  .offset-sm-57 {
    margin-left: 95%; }
  .offset-sm-58 {
    margin-left: 96.6666666667%; }
  .offset-sm-59 {
    margin-left: 98.3333333333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 1.6666666667%;
    max-width: 1.6666666667%; }
  .col-md-2 {
    flex: 0 0 3.3333333333%;
    max-width: 3.3333333333%; }
  .col-md-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-md-4 {
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%; }
  .col-md-5 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-md-7 {
    flex: 0 0 11.6666666667%;
    max-width: 11.6666666667%; }
  .col-md-8 {
    flex: 0 0 13.3333333333%;
    max-width: 13.3333333333%; }
  .col-md-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-md-10 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-11 {
    flex: 0 0 18.3333333333%;
    max-width: 18.3333333333%; }
  .col-md-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-md-13 {
    flex: 0 0 21.6666666667%;
    max-width: 21.6666666667%; }
  .col-md-14 {
    flex: 0 0 23.3333333333%;
    max-width: 23.3333333333%; }
  .col-md-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-16 {
    flex: 0 0 26.6666666667%;
    max-width: 26.6666666667%; }
  .col-md-17 {
    flex: 0 0 28.3333333333%;
    max-width: 28.3333333333%; }
  .col-md-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-md-19 {
    flex: 0 0 31.6666666667%;
    max-width: 31.6666666667%; }
  .col-md-20 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-md-22 {
    flex: 0 0 36.6666666667%;
    max-width: 36.6666666667%; }
  .col-md-23 {
    flex: 0 0 38.3333333333%;
    max-width: 38.3333333333%; }
  .col-md-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-md-25 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-26 {
    flex: 0 0 43.3333333333%;
    max-width: 43.3333333333%; }
  .col-md-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-md-28 {
    flex: 0 0 46.6666666667%;
    max-width: 46.6666666667%; }
  .col-md-29 {
    flex: 0 0 48.3333333333%;
    max-width: 48.3333333333%; }
  .col-md-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-31 {
    flex: 0 0 51.6666666667%;
    max-width: 51.6666666667%; }
  .col-md-32 {
    flex: 0 0 53.3333333333%;
    max-width: 53.3333333333%; }
  .col-md-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-md-34 {
    flex: 0 0 56.6666666667%;
    max-width: 56.6666666667%; }
  .col-md-35 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-md-37 {
    flex: 0 0 61.6666666667%;
    max-width: 61.6666666667%; }
  .col-md-38 {
    flex: 0 0 63.3333333333%;
    max-width: 63.3333333333%; }
  .col-md-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-md-40 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-41 {
    flex: 0 0 68.3333333333%;
    max-width: 68.3333333333%; }
  .col-md-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-md-43 {
    flex: 0 0 71.6666666667%;
    max-width: 71.6666666667%; }
  .col-md-44 {
    flex: 0 0 73.3333333333%;
    max-width: 73.3333333333%; }
  .col-md-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-46 {
    flex: 0 0 76.6666666667%;
    max-width: 76.6666666667%; }
  .col-md-47 {
    flex: 0 0 78.3333333333%;
    max-width: 78.3333333333%; }
  .col-md-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-md-49 {
    flex: 0 0 81.6666666667%;
    max-width: 81.6666666667%; }
  .col-md-50 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-md-52 {
    flex: 0 0 86.6666666667%;
    max-width: 86.6666666667%; }
  .col-md-53 {
    flex: 0 0 88.3333333333%;
    max-width: 88.3333333333%; }
  .col-md-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-md-55 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-56 {
    flex: 0 0 93.3333333333%;
    max-width: 93.3333333333%; }
  .col-md-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-md-58 {
    flex: 0 0 96.6666666667%;
    max-width: 96.6666666667%; }
  .col-md-59 {
    flex: 0 0 98.3333333333%;
    max-width: 98.3333333333%; }
  .col-md-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 61; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .order-md-25 {
    order: 25; }
  .order-md-26 {
    order: 26; }
  .order-md-27 {
    order: 27; }
  .order-md-28 {
    order: 28; }
  .order-md-29 {
    order: 29; }
  .order-md-30 {
    order: 30; }
  .order-md-31 {
    order: 31; }
  .order-md-32 {
    order: 32; }
  .order-md-33 {
    order: 33; }
  .order-md-34 {
    order: 34; }
  .order-md-35 {
    order: 35; }
  .order-md-36 {
    order: 36; }
  .order-md-37 {
    order: 37; }
  .order-md-38 {
    order: 38; }
  .order-md-39 {
    order: 39; }
  .order-md-40 {
    order: 40; }
  .order-md-41 {
    order: 41; }
  .order-md-42 {
    order: 42; }
  .order-md-43 {
    order: 43; }
  .order-md-44 {
    order: 44; }
  .order-md-45 {
    order: 45; }
  .order-md-46 {
    order: 46; }
  .order-md-47 {
    order: 47; }
  .order-md-48 {
    order: 48; }
  .order-md-49 {
    order: 49; }
  .order-md-50 {
    order: 50; }
  .order-md-51 {
    order: 51; }
  .order-md-52 {
    order: 52; }
  .order-md-53 {
    order: 53; }
  .order-md-54 {
    order: 54; }
  .order-md-55 {
    order: 55; }
  .order-md-56 {
    order: 56; }
  .order-md-57 {
    order: 57; }
  .order-md-58 {
    order: 58; }
  .order-md-59 {
    order: 59; }
  .order-md-60 {
    order: 60; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 1.6666666667%; }
  .offset-md-2 {
    margin-left: 3.3333333333%; }
  .offset-md-3 {
    margin-left: 5%; }
  .offset-md-4 {
    margin-left: 6.6666666667%; }
  .offset-md-5 {
    margin-left: 8.3333333333%; }
  .offset-md-6 {
    margin-left: 10%; }
  .offset-md-7 {
    margin-left: 11.6666666667%; }
  .offset-md-8 {
    margin-left: 13.3333333333%; }
  .offset-md-9 {
    margin-left: 15%; }
  .offset-md-10 {
    margin-left: 16.6666666667%; }
  .offset-md-11 {
    margin-left: 18.3333333333%; }
  .offset-md-12 {
    margin-left: 20%; }
  .offset-md-13 {
    margin-left: 21.6666666667%; }
  .offset-md-14 {
    margin-left: 23.3333333333%; }
  .offset-md-15 {
    margin-left: 25%; }
  .offset-md-16 {
    margin-left: 26.6666666667%; }
  .offset-md-17 {
    margin-left: 28.3333333333%; }
  .offset-md-18 {
    margin-left: 30%; }
  .offset-md-19 {
    margin-left: 31.6666666667%; }
  .offset-md-20 {
    margin-left: 33.3333333333%; }
  .offset-md-21 {
    margin-left: 35%; }
  .offset-md-22 {
    margin-left: 36.6666666667%; }
  .offset-md-23 {
    margin-left: 38.3333333333%; }
  .offset-md-24 {
    margin-left: 40%; }
  .offset-md-25 {
    margin-left: 41.6666666667%; }
  .offset-md-26 {
    margin-left: 43.3333333333%; }
  .offset-md-27 {
    margin-left: 45%; }
  .offset-md-28 {
    margin-left: 46.6666666667%; }
  .offset-md-29 {
    margin-left: 48.3333333333%; }
  .offset-md-30 {
    margin-left: 50%; }
  .offset-md-31 {
    margin-left: 51.6666666667%; }
  .offset-md-32 {
    margin-left: 53.3333333333%; }
  .offset-md-33 {
    margin-left: 55%; }
  .offset-md-34 {
    margin-left: 56.6666666667%; }
  .offset-md-35 {
    margin-left: 58.3333333333%; }
  .offset-md-36 {
    margin-left: 60%; }
  .offset-md-37 {
    margin-left: 61.6666666667%; }
  .offset-md-38 {
    margin-left: 63.3333333333%; }
  .offset-md-39 {
    margin-left: 65%; }
  .offset-md-40 {
    margin-left: 66.6666666667%; }
  .offset-md-41 {
    margin-left: 68.3333333333%; }
  .offset-md-42 {
    margin-left: 70%; }
  .offset-md-43 {
    margin-left: 71.6666666667%; }
  .offset-md-44 {
    margin-left: 73.3333333333%; }
  .offset-md-45 {
    margin-left: 75%; }
  .offset-md-46 {
    margin-left: 76.6666666667%; }
  .offset-md-47 {
    margin-left: 78.3333333333%; }
  .offset-md-48 {
    margin-left: 80%; }
  .offset-md-49 {
    margin-left: 81.6666666667%; }
  .offset-md-50 {
    margin-left: 83.3333333333%; }
  .offset-md-51 {
    margin-left: 85%; }
  .offset-md-52 {
    margin-left: 86.6666666667%; }
  .offset-md-53 {
    margin-left: 88.3333333333%; }
  .offset-md-54 {
    margin-left: 90%; }
  .offset-md-55 {
    margin-left: 91.6666666667%; }
  .offset-md-56 {
    margin-left: 93.3333333333%; }
  .offset-md-57 {
    margin-left: 95%; }
  .offset-md-58 {
    margin-left: 96.6666666667%; }
  .offset-md-59 {
    margin-left: 98.3333333333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 1.6666666667%;
    max-width: 1.6666666667%; }
  .col-lg-2 {
    flex: 0 0 3.3333333333%;
    max-width: 3.3333333333%; }
  .col-lg-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-lg-4 {
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%; }
  .col-lg-5 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-lg-7 {
    flex: 0 0 11.6666666667%;
    max-width: 11.6666666667%; }
  .col-lg-8 {
    flex: 0 0 13.3333333333%;
    max-width: 13.3333333333%; }
  .col-lg-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-lg-10 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-11 {
    flex: 0 0 18.3333333333%;
    max-width: 18.3333333333%; }
  .col-lg-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-lg-13 {
    flex: 0 0 21.6666666667%;
    max-width: 21.6666666667%; }
  .col-lg-14 {
    flex: 0 0 23.3333333333%;
    max-width: 23.3333333333%; }
  .col-lg-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-16 {
    flex: 0 0 26.6666666667%;
    max-width: 26.6666666667%; }
  .col-lg-17 {
    flex: 0 0 28.3333333333%;
    max-width: 28.3333333333%; }
  .col-lg-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-lg-19 {
    flex: 0 0 31.6666666667%;
    max-width: 31.6666666667%; }
  .col-lg-20 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-lg-22 {
    flex: 0 0 36.6666666667%;
    max-width: 36.6666666667%; }
  .col-lg-23 {
    flex: 0 0 38.3333333333%;
    max-width: 38.3333333333%; }
  .col-lg-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-lg-25 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-26 {
    flex: 0 0 43.3333333333%;
    max-width: 43.3333333333%; }
  .col-lg-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-lg-28 {
    flex: 0 0 46.6666666667%;
    max-width: 46.6666666667%; }
  .col-lg-29 {
    flex: 0 0 48.3333333333%;
    max-width: 48.3333333333%; }
  .col-lg-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-31 {
    flex: 0 0 51.6666666667%;
    max-width: 51.6666666667%; }
  .col-lg-32 {
    flex: 0 0 53.3333333333%;
    max-width: 53.3333333333%; }
  .col-lg-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-lg-34 {
    flex: 0 0 56.6666666667%;
    max-width: 56.6666666667%; }
  .col-lg-35 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-lg-37 {
    flex: 0 0 61.6666666667%;
    max-width: 61.6666666667%; }
  .col-lg-38 {
    flex: 0 0 63.3333333333%;
    max-width: 63.3333333333%; }
  .col-lg-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-lg-40 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-41 {
    flex: 0 0 68.3333333333%;
    max-width: 68.3333333333%; }
  .col-lg-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-lg-43 {
    flex: 0 0 71.6666666667%;
    max-width: 71.6666666667%; }
  .col-lg-44 {
    flex: 0 0 73.3333333333%;
    max-width: 73.3333333333%; }
  .col-lg-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-46 {
    flex: 0 0 76.6666666667%;
    max-width: 76.6666666667%; }
  .col-lg-47 {
    flex: 0 0 78.3333333333%;
    max-width: 78.3333333333%; }
  .col-lg-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-lg-49 {
    flex: 0 0 81.6666666667%;
    max-width: 81.6666666667%; }
  .col-lg-50 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-lg-52 {
    flex: 0 0 86.6666666667%;
    max-width: 86.6666666667%; }
  .col-lg-53 {
    flex: 0 0 88.3333333333%;
    max-width: 88.3333333333%; }
  .col-lg-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-lg-55 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-56 {
    flex: 0 0 93.3333333333%;
    max-width: 93.3333333333%; }
  .col-lg-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-lg-58 {
    flex: 0 0 96.6666666667%;
    max-width: 96.6666666667%; }
  .col-lg-59 {
    flex: 0 0 98.3333333333%;
    max-width: 98.3333333333%; }
  .col-lg-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 61; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .order-lg-25 {
    order: 25; }
  .order-lg-26 {
    order: 26; }
  .order-lg-27 {
    order: 27; }
  .order-lg-28 {
    order: 28; }
  .order-lg-29 {
    order: 29; }
  .order-lg-30 {
    order: 30; }
  .order-lg-31 {
    order: 31; }
  .order-lg-32 {
    order: 32; }
  .order-lg-33 {
    order: 33; }
  .order-lg-34 {
    order: 34; }
  .order-lg-35 {
    order: 35; }
  .order-lg-36 {
    order: 36; }
  .order-lg-37 {
    order: 37; }
  .order-lg-38 {
    order: 38; }
  .order-lg-39 {
    order: 39; }
  .order-lg-40 {
    order: 40; }
  .order-lg-41 {
    order: 41; }
  .order-lg-42 {
    order: 42; }
  .order-lg-43 {
    order: 43; }
  .order-lg-44 {
    order: 44; }
  .order-lg-45 {
    order: 45; }
  .order-lg-46 {
    order: 46; }
  .order-lg-47 {
    order: 47; }
  .order-lg-48 {
    order: 48; }
  .order-lg-49 {
    order: 49; }
  .order-lg-50 {
    order: 50; }
  .order-lg-51 {
    order: 51; }
  .order-lg-52 {
    order: 52; }
  .order-lg-53 {
    order: 53; }
  .order-lg-54 {
    order: 54; }
  .order-lg-55 {
    order: 55; }
  .order-lg-56 {
    order: 56; }
  .order-lg-57 {
    order: 57; }
  .order-lg-58 {
    order: 58; }
  .order-lg-59 {
    order: 59; }
  .order-lg-60 {
    order: 60; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 1.6666666667%; }
  .offset-lg-2 {
    margin-left: 3.3333333333%; }
  .offset-lg-3 {
    margin-left: 5%; }
  .offset-lg-4 {
    margin-left: 6.6666666667%; }
  .offset-lg-5 {
    margin-left: 8.3333333333%; }
  .offset-lg-6 {
    margin-left: 10%; }
  .offset-lg-7 {
    margin-left: 11.6666666667%; }
  .offset-lg-8 {
    margin-left: 13.3333333333%; }
  .offset-lg-9 {
    margin-left: 15%; }
  .offset-lg-10 {
    margin-left: 16.6666666667%; }
  .offset-lg-11 {
    margin-left: 18.3333333333%; }
  .offset-lg-12 {
    margin-left: 20%; }
  .offset-lg-13 {
    margin-left: 21.6666666667%; }
  .offset-lg-14 {
    margin-left: 23.3333333333%; }
  .offset-lg-15 {
    margin-left: 25%; }
  .offset-lg-16 {
    margin-left: 26.6666666667%; }
  .offset-lg-17 {
    margin-left: 28.3333333333%; }
  .offset-lg-18 {
    margin-left: 30%; }
  .offset-lg-19 {
    margin-left: 31.6666666667%; }
  .offset-lg-20 {
    margin-left: 33.3333333333%; }
  .offset-lg-21 {
    margin-left: 35%; }
  .offset-lg-22 {
    margin-left: 36.6666666667%; }
  .offset-lg-23 {
    margin-left: 38.3333333333%; }
  .offset-lg-24 {
    margin-left: 40%; }
  .offset-lg-25 {
    margin-left: 41.6666666667%; }
  .offset-lg-26 {
    margin-left: 43.3333333333%; }
  .offset-lg-27 {
    margin-left: 45%; }
  .offset-lg-28 {
    margin-left: 46.6666666667%; }
  .offset-lg-29 {
    margin-left: 48.3333333333%; }
  .offset-lg-30 {
    margin-left: 50%; }
  .offset-lg-31 {
    margin-left: 51.6666666667%; }
  .offset-lg-32 {
    margin-left: 53.3333333333%; }
  .offset-lg-33 {
    margin-left: 55%; }
  .offset-lg-34 {
    margin-left: 56.6666666667%; }
  .offset-lg-35 {
    margin-left: 58.3333333333%; }
  .offset-lg-36 {
    margin-left: 60%; }
  .offset-lg-37 {
    margin-left: 61.6666666667%; }
  .offset-lg-38 {
    margin-left: 63.3333333333%; }
  .offset-lg-39 {
    margin-left: 65%; }
  .offset-lg-40 {
    margin-left: 66.6666666667%; }
  .offset-lg-41 {
    margin-left: 68.3333333333%; }
  .offset-lg-42 {
    margin-left: 70%; }
  .offset-lg-43 {
    margin-left: 71.6666666667%; }
  .offset-lg-44 {
    margin-left: 73.3333333333%; }
  .offset-lg-45 {
    margin-left: 75%; }
  .offset-lg-46 {
    margin-left: 76.6666666667%; }
  .offset-lg-47 {
    margin-left: 78.3333333333%; }
  .offset-lg-48 {
    margin-left: 80%; }
  .offset-lg-49 {
    margin-left: 81.6666666667%; }
  .offset-lg-50 {
    margin-left: 83.3333333333%; }
  .offset-lg-51 {
    margin-left: 85%; }
  .offset-lg-52 {
    margin-left: 86.6666666667%; }
  .offset-lg-53 {
    margin-left: 88.3333333333%; }
  .offset-lg-54 {
    margin-left: 90%; }
  .offset-lg-55 {
    margin-left: 91.6666666667%; }
  .offset-lg-56 {
    margin-left: 93.3333333333%; }
  .offset-lg-57 {
    margin-left: 95%; }
  .offset-lg-58 {
    margin-left: 96.6666666667%; }
  .offset-lg-59 {
    margin-left: 98.3333333333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 1.6666666667%;
    max-width: 1.6666666667%; }
  .col-xl-2 {
    flex: 0 0 3.3333333333%;
    max-width: 3.3333333333%; }
  .col-xl-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-xl-4 {
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%; }
  .col-xl-5 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-xl-7 {
    flex: 0 0 11.6666666667%;
    max-width: 11.6666666667%; }
  .col-xl-8 {
    flex: 0 0 13.3333333333%;
    max-width: 13.3333333333%; }
  .col-xl-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-xl-10 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-11 {
    flex: 0 0 18.3333333333%;
    max-width: 18.3333333333%; }
  .col-xl-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-xl-13 {
    flex: 0 0 21.6666666667%;
    max-width: 21.6666666667%; }
  .col-xl-14 {
    flex: 0 0 23.3333333333%;
    max-width: 23.3333333333%; }
  .col-xl-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-16 {
    flex: 0 0 26.6666666667%;
    max-width: 26.6666666667%; }
  .col-xl-17 {
    flex: 0 0 28.3333333333%;
    max-width: 28.3333333333%; }
  .col-xl-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-xl-19 {
    flex: 0 0 31.6666666667%;
    max-width: 31.6666666667%; }
  .col-xl-20 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-xl-22 {
    flex: 0 0 36.6666666667%;
    max-width: 36.6666666667%; }
  .col-xl-23 {
    flex: 0 0 38.3333333333%;
    max-width: 38.3333333333%; }
  .col-xl-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-xl-25 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-26 {
    flex: 0 0 43.3333333333%;
    max-width: 43.3333333333%; }
  .col-xl-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-xl-28 {
    flex: 0 0 46.6666666667%;
    max-width: 46.6666666667%; }
  .col-xl-29 {
    flex: 0 0 48.3333333333%;
    max-width: 48.3333333333%; }
  .col-xl-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-31 {
    flex: 0 0 51.6666666667%;
    max-width: 51.6666666667%; }
  .col-xl-32 {
    flex: 0 0 53.3333333333%;
    max-width: 53.3333333333%; }
  .col-xl-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-xl-34 {
    flex: 0 0 56.6666666667%;
    max-width: 56.6666666667%; }
  .col-xl-35 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-xl-37 {
    flex: 0 0 61.6666666667%;
    max-width: 61.6666666667%; }
  .col-xl-38 {
    flex: 0 0 63.3333333333%;
    max-width: 63.3333333333%; }
  .col-xl-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-xl-40 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-41 {
    flex: 0 0 68.3333333333%;
    max-width: 68.3333333333%; }
  .col-xl-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-xl-43 {
    flex: 0 0 71.6666666667%;
    max-width: 71.6666666667%; }
  .col-xl-44 {
    flex: 0 0 73.3333333333%;
    max-width: 73.3333333333%; }
  .col-xl-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-46 {
    flex: 0 0 76.6666666667%;
    max-width: 76.6666666667%; }
  .col-xl-47 {
    flex: 0 0 78.3333333333%;
    max-width: 78.3333333333%; }
  .col-xl-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-xl-49 {
    flex: 0 0 81.6666666667%;
    max-width: 81.6666666667%; }
  .col-xl-50 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-xl-52 {
    flex: 0 0 86.6666666667%;
    max-width: 86.6666666667%; }
  .col-xl-53 {
    flex: 0 0 88.3333333333%;
    max-width: 88.3333333333%; }
  .col-xl-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-xl-55 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-56 {
    flex: 0 0 93.3333333333%;
    max-width: 93.3333333333%; }
  .col-xl-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-xl-58 {
    flex: 0 0 96.6666666667%;
    max-width: 96.6666666667%; }
  .col-xl-59 {
    flex: 0 0 98.3333333333%;
    max-width: 98.3333333333%; }
  .col-xl-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 61; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .order-xl-25 {
    order: 25; }
  .order-xl-26 {
    order: 26; }
  .order-xl-27 {
    order: 27; }
  .order-xl-28 {
    order: 28; }
  .order-xl-29 {
    order: 29; }
  .order-xl-30 {
    order: 30; }
  .order-xl-31 {
    order: 31; }
  .order-xl-32 {
    order: 32; }
  .order-xl-33 {
    order: 33; }
  .order-xl-34 {
    order: 34; }
  .order-xl-35 {
    order: 35; }
  .order-xl-36 {
    order: 36; }
  .order-xl-37 {
    order: 37; }
  .order-xl-38 {
    order: 38; }
  .order-xl-39 {
    order: 39; }
  .order-xl-40 {
    order: 40; }
  .order-xl-41 {
    order: 41; }
  .order-xl-42 {
    order: 42; }
  .order-xl-43 {
    order: 43; }
  .order-xl-44 {
    order: 44; }
  .order-xl-45 {
    order: 45; }
  .order-xl-46 {
    order: 46; }
  .order-xl-47 {
    order: 47; }
  .order-xl-48 {
    order: 48; }
  .order-xl-49 {
    order: 49; }
  .order-xl-50 {
    order: 50; }
  .order-xl-51 {
    order: 51; }
  .order-xl-52 {
    order: 52; }
  .order-xl-53 {
    order: 53; }
  .order-xl-54 {
    order: 54; }
  .order-xl-55 {
    order: 55; }
  .order-xl-56 {
    order: 56; }
  .order-xl-57 {
    order: 57; }
  .order-xl-58 {
    order: 58; }
  .order-xl-59 {
    order: 59; }
  .order-xl-60 {
    order: 60; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 1.6666666667%; }
  .offset-xl-2 {
    margin-left: 3.3333333333%; }
  .offset-xl-3 {
    margin-left: 5%; }
  .offset-xl-4 {
    margin-left: 6.6666666667%; }
  .offset-xl-5 {
    margin-left: 8.3333333333%; }
  .offset-xl-6 {
    margin-left: 10%; }
  .offset-xl-7 {
    margin-left: 11.6666666667%; }
  .offset-xl-8 {
    margin-left: 13.3333333333%; }
  .offset-xl-9 {
    margin-left: 15%; }
  .offset-xl-10 {
    margin-left: 16.6666666667%; }
  .offset-xl-11 {
    margin-left: 18.3333333333%; }
  .offset-xl-12 {
    margin-left: 20%; }
  .offset-xl-13 {
    margin-left: 21.6666666667%; }
  .offset-xl-14 {
    margin-left: 23.3333333333%; }
  .offset-xl-15 {
    margin-left: 25%; }
  .offset-xl-16 {
    margin-left: 26.6666666667%; }
  .offset-xl-17 {
    margin-left: 28.3333333333%; }
  .offset-xl-18 {
    margin-left: 30%; }
  .offset-xl-19 {
    margin-left: 31.6666666667%; }
  .offset-xl-20 {
    margin-left: 33.3333333333%; }
  .offset-xl-21 {
    margin-left: 35%; }
  .offset-xl-22 {
    margin-left: 36.6666666667%; }
  .offset-xl-23 {
    margin-left: 38.3333333333%; }
  .offset-xl-24 {
    margin-left: 40%; }
  .offset-xl-25 {
    margin-left: 41.6666666667%; }
  .offset-xl-26 {
    margin-left: 43.3333333333%; }
  .offset-xl-27 {
    margin-left: 45%; }
  .offset-xl-28 {
    margin-left: 46.6666666667%; }
  .offset-xl-29 {
    margin-left: 48.3333333333%; }
  .offset-xl-30 {
    margin-left: 50%; }
  .offset-xl-31 {
    margin-left: 51.6666666667%; }
  .offset-xl-32 {
    margin-left: 53.3333333333%; }
  .offset-xl-33 {
    margin-left: 55%; }
  .offset-xl-34 {
    margin-left: 56.6666666667%; }
  .offset-xl-35 {
    margin-left: 58.3333333333%; }
  .offset-xl-36 {
    margin-left: 60%; }
  .offset-xl-37 {
    margin-left: 61.6666666667%; }
  .offset-xl-38 {
    margin-left: 63.3333333333%; }
  .offset-xl-39 {
    margin-left: 65%; }
  .offset-xl-40 {
    margin-left: 66.6666666667%; }
  .offset-xl-41 {
    margin-left: 68.3333333333%; }
  .offset-xl-42 {
    margin-left: 70%; }
  .offset-xl-43 {
    margin-left: 71.6666666667%; }
  .offset-xl-44 {
    margin-left: 73.3333333333%; }
  .offset-xl-45 {
    margin-left: 75%; }
  .offset-xl-46 {
    margin-left: 76.6666666667%; }
  .offset-xl-47 {
    margin-left: 78.3333333333%; }
  .offset-xl-48 {
    margin-left: 80%; }
  .offset-xl-49 {
    margin-left: 81.6666666667%; }
  .offset-xl-50 {
    margin-left: 83.3333333333%; }
  .offset-xl-51 {
    margin-left: 85%; }
  .offset-xl-52 {
    margin-left: 86.6666666667%; }
  .offset-xl-53 {
    margin-left: 88.3333333333%; }
  .offset-xl-54 {
    margin-left: 90%; }
  .offset-xl-55 {
    margin-left: 91.6666666667%; }
  .offset-xl-56 {
    margin-left: 93.3333333333%; }
  .offset-xl-57 {
    margin-left: 95%; }
  .offset-xl-58 {
    margin-left: 96.6666666667%; }
  .offset-xl-59 {
    margin-left: 98.3333333333%; } }

@media (min-width: 1441px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xxl-1 {
    flex: 0 0 1.6666666667%;
    max-width: 1.6666666667%; }
  .col-xxl-2 {
    flex: 0 0 3.3333333333%;
    max-width: 3.3333333333%; }
  .col-xxl-3 {
    flex: 0 0 5%;
    max-width: 5%; }
  .col-xxl-4 {
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%; }
  .col-xxl-5 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xxl-6 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-xxl-7 {
    flex: 0 0 11.6666666667%;
    max-width: 11.6666666667%; }
  .col-xxl-8 {
    flex: 0 0 13.3333333333%;
    max-width: 13.3333333333%; }
  .col-xxl-9 {
    flex: 0 0 15%;
    max-width: 15%; }
  .col-xxl-10 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xxl-11 {
    flex: 0 0 18.3333333333%;
    max-width: 18.3333333333%; }
  .col-xxl-12 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-xxl-13 {
    flex: 0 0 21.6666666667%;
    max-width: 21.6666666667%; }
  .col-xxl-14 {
    flex: 0 0 23.3333333333%;
    max-width: 23.3333333333%; }
  .col-xxl-15 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-16 {
    flex: 0 0 26.6666666667%;
    max-width: 26.6666666667%; }
  .col-xxl-17 {
    flex: 0 0 28.3333333333%;
    max-width: 28.3333333333%; }
  .col-xxl-18 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-xxl-19 {
    flex: 0 0 31.6666666667%;
    max-width: 31.6666666667%; }
  .col-xxl-20 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xxl-21 {
    flex: 0 0 35%;
    max-width: 35%; }
  .col-xxl-22 {
    flex: 0 0 36.6666666667%;
    max-width: 36.6666666667%; }
  .col-xxl-23 {
    flex: 0 0 38.3333333333%;
    max-width: 38.3333333333%; }
  .col-xxl-24 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-xxl-25 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xxl-26 {
    flex: 0 0 43.3333333333%;
    max-width: 43.3333333333%; }
  .col-xxl-27 {
    flex: 0 0 45%;
    max-width: 45%; }
  .col-xxl-28 {
    flex: 0 0 46.6666666667%;
    max-width: 46.6666666667%; }
  .col-xxl-29 {
    flex: 0 0 48.3333333333%;
    max-width: 48.3333333333%; }
  .col-xxl-30 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-31 {
    flex: 0 0 51.6666666667%;
    max-width: 51.6666666667%; }
  .col-xxl-32 {
    flex: 0 0 53.3333333333%;
    max-width: 53.3333333333%; }
  .col-xxl-33 {
    flex: 0 0 55%;
    max-width: 55%; }
  .col-xxl-34 {
    flex: 0 0 56.6666666667%;
    max-width: 56.6666666667%; }
  .col-xxl-35 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xxl-36 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-xxl-37 {
    flex: 0 0 61.6666666667%;
    max-width: 61.6666666667%; }
  .col-xxl-38 {
    flex: 0 0 63.3333333333%;
    max-width: 63.3333333333%; }
  .col-xxl-39 {
    flex: 0 0 65%;
    max-width: 65%; }
  .col-xxl-40 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xxl-41 {
    flex: 0 0 68.3333333333%;
    max-width: 68.3333333333%; }
  .col-xxl-42 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-xxl-43 {
    flex: 0 0 71.6666666667%;
    max-width: 71.6666666667%; }
  .col-xxl-44 {
    flex: 0 0 73.3333333333%;
    max-width: 73.3333333333%; }
  .col-xxl-45 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-46 {
    flex: 0 0 76.6666666667%;
    max-width: 76.6666666667%; }
  .col-xxl-47 {
    flex: 0 0 78.3333333333%;
    max-width: 78.3333333333%; }
  .col-xxl-48 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-xxl-49 {
    flex: 0 0 81.6666666667%;
    max-width: 81.6666666667%; }
  .col-xxl-50 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xxl-51 {
    flex: 0 0 85%;
    max-width: 85%; }
  .col-xxl-52 {
    flex: 0 0 86.6666666667%;
    max-width: 86.6666666667%; }
  .col-xxl-53 {
    flex: 0 0 88.3333333333%;
    max-width: 88.3333333333%; }
  .col-xxl-54 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-xxl-55 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xxl-56 {
    flex: 0 0 93.3333333333%;
    max-width: 93.3333333333%; }
  .col-xxl-57 {
    flex: 0 0 95%;
    max-width: 95%; }
  .col-xxl-58 {
    flex: 0 0 96.6666666667%;
    max-width: 96.6666666667%; }
  .col-xxl-59 {
    flex: 0 0 98.3333333333%;
    max-width: 98.3333333333%; }
  .col-xxl-60 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 61; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .order-xxl-25 {
    order: 25; }
  .order-xxl-26 {
    order: 26; }
  .order-xxl-27 {
    order: 27; }
  .order-xxl-28 {
    order: 28; }
  .order-xxl-29 {
    order: 29; }
  .order-xxl-30 {
    order: 30; }
  .order-xxl-31 {
    order: 31; }
  .order-xxl-32 {
    order: 32; }
  .order-xxl-33 {
    order: 33; }
  .order-xxl-34 {
    order: 34; }
  .order-xxl-35 {
    order: 35; }
  .order-xxl-36 {
    order: 36; }
  .order-xxl-37 {
    order: 37; }
  .order-xxl-38 {
    order: 38; }
  .order-xxl-39 {
    order: 39; }
  .order-xxl-40 {
    order: 40; }
  .order-xxl-41 {
    order: 41; }
  .order-xxl-42 {
    order: 42; }
  .order-xxl-43 {
    order: 43; }
  .order-xxl-44 {
    order: 44; }
  .order-xxl-45 {
    order: 45; }
  .order-xxl-46 {
    order: 46; }
  .order-xxl-47 {
    order: 47; }
  .order-xxl-48 {
    order: 48; }
  .order-xxl-49 {
    order: 49; }
  .order-xxl-50 {
    order: 50; }
  .order-xxl-51 {
    order: 51; }
  .order-xxl-52 {
    order: 52; }
  .order-xxl-53 {
    order: 53; }
  .order-xxl-54 {
    order: 54; }
  .order-xxl-55 {
    order: 55; }
  .order-xxl-56 {
    order: 56; }
  .order-xxl-57 {
    order: 57; }
  .order-xxl-58 {
    order: 58; }
  .order-xxl-59 {
    order: 59; }
  .order-xxl-60 {
    order: 60; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 1.6666666667%; }
  .offset-xxl-2 {
    margin-left: 3.3333333333%; }
  .offset-xxl-3 {
    margin-left: 5%; }
  .offset-xxl-4 {
    margin-left: 6.6666666667%; }
  .offset-xxl-5 {
    margin-left: 8.3333333333%; }
  .offset-xxl-6 {
    margin-left: 10%; }
  .offset-xxl-7 {
    margin-left: 11.6666666667%; }
  .offset-xxl-8 {
    margin-left: 13.3333333333%; }
  .offset-xxl-9 {
    margin-left: 15%; }
  .offset-xxl-10 {
    margin-left: 16.6666666667%; }
  .offset-xxl-11 {
    margin-left: 18.3333333333%; }
  .offset-xxl-12 {
    margin-left: 20%; }
  .offset-xxl-13 {
    margin-left: 21.6666666667%; }
  .offset-xxl-14 {
    margin-left: 23.3333333333%; }
  .offset-xxl-15 {
    margin-left: 25%; }
  .offset-xxl-16 {
    margin-left: 26.6666666667%; }
  .offset-xxl-17 {
    margin-left: 28.3333333333%; }
  .offset-xxl-18 {
    margin-left: 30%; }
  .offset-xxl-19 {
    margin-left: 31.6666666667%; }
  .offset-xxl-20 {
    margin-left: 33.3333333333%; }
  .offset-xxl-21 {
    margin-left: 35%; }
  .offset-xxl-22 {
    margin-left: 36.6666666667%; }
  .offset-xxl-23 {
    margin-left: 38.3333333333%; }
  .offset-xxl-24 {
    margin-left: 40%; }
  .offset-xxl-25 {
    margin-left: 41.6666666667%; }
  .offset-xxl-26 {
    margin-left: 43.3333333333%; }
  .offset-xxl-27 {
    margin-left: 45%; }
  .offset-xxl-28 {
    margin-left: 46.6666666667%; }
  .offset-xxl-29 {
    margin-left: 48.3333333333%; }
  .offset-xxl-30 {
    margin-left: 50%; }
  .offset-xxl-31 {
    margin-left: 51.6666666667%; }
  .offset-xxl-32 {
    margin-left: 53.3333333333%; }
  .offset-xxl-33 {
    margin-left: 55%; }
  .offset-xxl-34 {
    margin-left: 56.6666666667%; }
  .offset-xxl-35 {
    margin-left: 58.3333333333%; }
  .offset-xxl-36 {
    margin-left: 60%; }
  .offset-xxl-37 {
    margin-left: 61.6666666667%; }
  .offset-xxl-38 {
    margin-left: 63.3333333333%; }
  .offset-xxl-39 {
    margin-left: 65%; }
  .offset-xxl-40 {
    margin-left: 66.6666666667%; }
  .offset-xxl-41 {
    margin-left: 68.3333333333%; }
  .offset-xxl-42 {
    margin-left: 70%; }
  .offset-xxl-43 {
    margin-left: 71.6666666667%; }
  .offset-xxl-44 {
    margin-left: 73.3333333333%; }
  .offset-xxl-45 {
    margin-left: 75%; }
  .offset-xxl-46 {
    margin-left: 76.6666666667%; }
  .offset-xxl-47 {
    margin-left: 78.3333333333%; }
  .offset-xxl-48 {
    margin-left: 80%; }
  .offset-xxl-49 {
    margin-left: 81.6666666667%; }
  .offset-xxl-50 {
    margin-left: 83.3333333333%; }
  .offset-xxl-51 {
    margin-left: 85%; }
  .offset-xxl-52 {
    margin-left: 86.6666666667%; }
  .offset-xxl-53 {
    margin-left: 88.3333333333%; }
  .offset-xxl-54 {
    margin-left: 90%; }
  .offset-xxl-55 {
    margin-left: 91.6666666667%; }
  .offset-xxl-56 {
    margin-left: 93.3333333333%; }
  .offset-xxl-57 {
    margin-left: 95%; }
  .offset-xxl-58 {
    margin-left: 96.6666666667%; }
  .offset-xxl-59 {
    margin-left: 98.3333333333%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, #sticky-nav .container-fluid .row .search .button {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1441px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1441px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, #sticky-nav .container-fluid .row .search .button {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1441px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1441px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

/**
 * Fontawesome
 */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("../assets/fonts/font-awesome/fontawesome-webfont.eot?v=4.7.0");
  src: url("../assets/fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../assets/fonts/font-awesome/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../assets/fonts/font-awesome/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../assets/fonts/font-awesome/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../assets/fonts/font-awesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * Slick
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../assets/fonts/slick/slick.eot");
  src: url("../assets/fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/slick/slick.woff") format("woff"), url("../assets/fonts/slick/slick.ttf") format("truetype"), url("../assets/fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/**
 * Custom
 */
/**
 * Custom fonts
 */
/**
 * Px Grotesk
 */
@font-face {
  font-family: 'PxGrotesk';
  src: url("../assets/fonts/px-grotesk/PxGrotesk-Light.eot");
  src: url("../assets/fonts/px-grotesk/PxGrotesk-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/px-grotesk/PxGrotesk-Light.woff") format("woff"), url("../assets/fonts/px-grotesk/PxGrotesk-Light.ttf") format("truetype"), url("../assets/fonts/px-grotesk/PxGrotesk-Light.svg#youworkforthem") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'PxGrotesk';
  src: url("../assets/fonts/px-grotesk/PxGrotesk-LightItalic.eot");
  src: url("../assets/fonts/px-grotesk/PxGrotesk-LightItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/px-grotesk/PxGrotesk-LightItalic.woff") format("woff"), url("../assets/fonts/px-grotesk/PxGrotesk-LightItalic.ttf") format("truetype"), url("../assets/fonts/px-grotesk/PxGrotesk-LightItalic.svg#youworkforthem") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'PxGrotesk';
  src: url("../assets/fonts/px-grotesk/PxGrotesk-Regular.eot");
  src: url("../assets/fonts/px-grotesk/PxGrotesk-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/px-grotesk/PxGrotesk-Regular.woff") format("woff"), url("../assets/fonts/px-grotesk/PxGrotesk-Regular.ttf") format("truetype"), url("../assets/fonts/px-grotesk/PxGrotesk-Regular.svg#youworkforthem") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'PxGrotesk';
  src: url("../assets/fonts/px-grotesk/PxGrotesk-RegularItalic.eot");
  src: url("../assets/fonts/px-grotesk/PxGrotesk-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/px-grotesk/PxGrotesk-RegularItalic.woff") format("woff"), url("../assets/fonts/px-grotesk/PxGrotesk-RegularItalic.ttf") format("truetype"), url("../assets/fonts/px-grotesk/PxGrotesk-RegularItalic.svg#youworkforthem") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'PxGrotesk';
  src: url("../assets/fonts/px-grotesk/PxGrotesk-Bold.eot");
  src: url("../assets/fonts/px-grotesk/PxGrotesk-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/px-grotesk/PxGrotesk-Bold.woff") format("woff"), url("../assets/fonts/px-grotesk/PxGrotesk-Bold.ttf") format("truetype"), url("../assets/fonts/px-grotesk/PxGrotesk-Bold.svg#youworkforthem") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'PxGrotesk';
  src: url("../assets/fonts/px-grotesk/PxGrotesk-BoldItalic.eot");
  src: url("../assets/fonts/px-grotesk/PxGrotesk-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/px-grotesk/PxGrotesk-BoldItalic.woff") format("woff"), url("../assets/fonts/px-grotesk/PxGrotesk-BoldItalic.ttf") format("truetype"), url("../assets/fonts/px-grotesk/PxGrotesk-BoldItalic.svg#youworkforthem") format("svg");
  font-weight: 500;
  font-style: italic; }

/**
 * Custom icons
 */
@font-face {
  font-family: 'CustomIcons';
  src: url("../assets/fonts/icons/icons.eot");
  src: url("../assets/fonts/icons/icons.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/icons/icons.woff") format("woff"), url("../assets/fonts/icons/icons.ttf") format("truetype"), url("../assets/fonts/icons/icons.svg#youworkforthem") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * Fontface mixin
 * @param string $weight Font family
 * @param int    $weight Font weight
 * @param type   $style  Font Style
 * @return rule
 */
/**
 * PxGrotesk mixin
 * @param int  $weight Font weight
 * @param type $style  Font Style
 * @return rule
 */
/**
 * Input placeholder styles
 */
/**
 * Last child margin reset
 * @param property $property
 */
/**
 * Creates a set of options (extends the current selector)
 * @param list $combos List example $list : ("selector-added-class" : ( "property" value, "property" value ))
 */
/**
 * Padded content
 */
/**
 * Slick dots overrides
 * @param int  $size    Dots size
 * @param list $color   List containing initial, hover and selected colors
 * @param int  $spacing Space between dots
 */
/**
 * Slick arrows overrides
 * @param int  $size      Arrows size
 * @param list $color     List containing initial, hover and disabled colors
 * @param int  $translate Special adjustment measure (depending on column offset for the slick container)
 */
/**
 * Custom icons
 */
/**
 * Vertical align using position absolute
 */
/**
 * Gradients
 */
/**
 *  Common styles
 */
body {
  background-color: #292929;
  font-family: "PxGrotesk";
  font-weight: 300;
  font-style: normal;
  color: #363636; }

a {
  outline: none; }

sup {
  position: absolute;
  font-size: 0.7em; }

/**
 * Remove iOS custom form styles
 */
textarea,
input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
  border-radius: 0; }

/**
 * Sup custom styles
 */
sup {
  position: relative;
  top: -0.5em;
  font-size: 0.7em;
  line-height: 0;
  vertical-align: baseline; }

/**
 * Plugins overrides
 */
.fancybox-is-open .fancybox-bg {
  opacity: 1 !important; }

/**
 * Utilities
 */
.grow {
  flex: 1; }

/**
 * Font styles
 */
h1, h2, h3, h4, h6 {
  font-family: "PxGrotesk";
  font-weight: 500;
  font-style: normal; }
  h1 span, h2 span, h3 span, h4 span, h6 span {
    font-weight: 300; }

h2 {
  font-size: 2.2em; }
  @media (min-width: 768px) {
    h2 {
      font-size: 2.4em; } }
  @media (min-width: 992px) {
    h2 {
      font-size: 2.4em; } }

h3 {
  margin-bottom: 0.8em;
  font-size: 1.8em; }
  @media (min-width: 768px) {
    h3 {
      margin-bottom: 0.5em;
      font-size: 1.9em; } }
  @media (min-width: 992px) {
    h3 {
      margin-bottom: 0.5em;
      font-size: 2em; } }

h4 {
  margin-bottom: 0.8em;
  font-size: 1.3em; }
  @media (min-width: 768px) {
    h4 {
      margin-bottom: 0.5em;
      font-size: 1.4em; } }
  @media (min-width: 992px) {
    h4 {
      margin-bottom: 0.5em;
      font-size: 1.5em; } }

h5 {
  margin-bottom: 0.8em;
  opacity: .5;
  font-family: "PxGrotesk";
  font-weight: 300;
  font-style: normal;
  font-size: 1.1em;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    h5 {
      margin-bottom: 0.6em;
      font-size: 1em; } }
  @media (min-width: 992px) {
    h5 {
      margin-bottom: 0.9em;
      font-size: 1em; } }

h6 {
  margin-bottom: 0.8em;
  font-size: 1.2em; }
  @media (min-width: 768px) {
    h6 {
      margin-bottom: 0.7em;
      font-size: 1.15em; } }
  @media (min-width: 992px) {
    h6 {
      margin-bottom: 0.8em;
      font-size: 1.2em; } }

p {
  margin-bottom: 1.45em;
  font-family: "PxGrotesk";
  font-weight: 300;
  font-style: normal;
  font-size: 1.05em;
  line-height: 1.6em; }
  @media (min-width: 768px) {
    p {
      margin-bottom: 1.65em;
      font-size: 1.1em; } }
  @media (min-width: 992px) {
    p {
      margin-bottom: 1.8em;
      font-size: 1.15em; } }
  p:last-child {
    margin-bottom: 0; }
  p strong {
    font-weight: 500; }
    p strong.medium {
      font-weight: 400; }

hr.spacer {
  width: 4.2em;
  margin: 0 0 1.2em 0;
  border-width: 0 0 1px 0;
  border-color: transparent transparent #cccccc transparent;
  border-style: solid;
  text-align: left;
  color: transparent; }
  hr.spacer.centered {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  @media (min-width: 768px) {
    hr.spacer {
      width: 3.6em;
      margin-bottom: 1.6em; } }
  @media (min-width: 992px) {
    hr.spacer {
      width: 4em;
      margin-bottom: 1.8em; } }
  hr.spacer.white {
    border-color: transparent transparent white transparent; }
  hr.spacer.blue {
    border-color: transparent transparent #3da6eb transparent; }
  hr.spacer.light-blue {
    border-color: transparent transparent #93cff7 transparent; }
  hr.spacer.green {
    border-color: transparent transparent #00a8b4 transparent; }
  hr.spacer.darker-green {
    border-color: transparent transparent #018790 transparent; }

/**
 * Buttons styles
 */
.button:not([class^="col"]) {
  display: inline-block;
  padding: 0.8em 1.8em 0.7em;
  font-family: "PxGrotesk";
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none; }
  .button:not([class^="col"]).disabled {
    cursor: not-allowed; }
  .button:not([class^="col"]).xsmall {
    font-size: 80%; }
  .button:not([class^="col"]).small {
    font-size: 85%; }
  .button:not([class^="col"]).medium {
    font-size: 110%; }
  .button:not([class^="col"]).large {
    font-size: 125%; }
  .button:not([class^="col"]).wider {
    padding: 0.8em 2.4em 0.7em; }
  .button:not([class^="col"]).black {
    color: white;
    background-color: black; }
  .button:not([class^="col"]).light-grey {
    color: white;
    background-color: #cccccc; }
  .button:not([class^="col"]).white {
    color: black;
    background-color: white; }
  .button:not([class^="col"]).primary {
    color: white;
    background-color: #ff5100; }
  .button:not([class^="col"]).green {
    color: white;
    background-color: #00a8b4; }
  .button:not([class^="col"]).outline {
    background-color: transparent;
    border: 1px solid; }
    .button:not([class^="col"]).outline.black {
      color: black;
      border-color: black; }
    .button:not([class^="col"]).outline.light-grey {
      color: #cccccc;
      border-color: #cccccc; }
    .button:not([class^="col"]).outline.white {
      color: white;
      border-color: white; }
    .button:not([class^="col"]).outline.primary {
      color: #ff5100;
      border-color: #ff5100; }

/**
 * Tooltips styles
 */
.tooltip {
  z-index: 5;
  position: absolute;
  padding: 0.4em 1em;
  border-radius: 0.25em;
  background-color: #0b0b0b;
  font-family: "PxGrotesk";
  font-weight: 300;
  font-style: normal;
  font-size: 0.8em;
  color: white;
  text-align: center; }
  .tooltip:before {
    position: absolute;
    left: 50%;
    margin-left: -0.4em;
    content: ' ';
    border-width: 0.4em;
    border-style: solid; }
  .tooltip.down:before {
    bottom: 100%;
    border-color: transparent transparent #0b0b0b transparent; }
  .tooltip.up:before {
    top: 100%;
    border-color: #0b0b0b transparent transparent transparent; }

/**
 * Sticky nav
 */
@keyframes openfirst {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(0.7em) rotate(45deg); }
  100% {
    transform: translateY(0.7em) rotate(45deg); } }

@keyframes opensecond {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(0) rotate(45deg); }
  100% {
    opacity: 0; } }

@keyframes openthird {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-0.7em) rotate(45deg); }
  100% {
    transform: translateY(-0.7em) rotate(135deg); } }

#sticky-nav {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.25s ease; }
  #sticky-nav .container-fluid {
    max-width: 1440px;
    margin: 0 auto;
    background-color: #141414;
    color: white;
    padding: 0.6em 0; }
    @media (min-width: 768px) {
      #sticky-nav .container-fluid {
        padding: 0.8em 0; } }
    #sticky-nav .container-fluid .row .to-front {
      z-index: 4; }
      @media (min-width: 1441px) {
        #sticky-nav .container-fluid .row .to-front {
          max-width: 2%; } }
    #sticky-nav .container-fluid .row .logo {
      display: inline-block;
      width: 100%;
      height: auto; }
      @media (min-width: 768px) {
        #sticky-nav .container-fluid .row .logo {
          margin: 0.4em 0; } }
      @media (min-width: 992px) {
        #sticky-nav .container-fluid .row .logo {
          max-width: 75%;
          margin: 0.25em 0; } }
      @media (min-width: 1441px) {
        #sticky-nav .container-fluid .row .logo {
          max-width: none; } }
    #sticky-nav .container-fluid .row .selector {
      cursor: pointer;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      text-align: center;
      text-transform: uppercase; }
      @media (max-width: 767.98px) {
        #sticky-nav .container-fluid .row .selector {
          font-size: 1.4em; } }
      @media (min-width: 576px) {
        #sticky-nav .container-fluid .row .selector {
          font-size: 1.1em; } }
      @media (min-width: 992px) {
        #sticky-nav .container-fluid .row .selector {
          font-size: 0.9em; } }
      #sticky-nav .container-fluid .row .selector i {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        #sticky-nav .container-fluid .row .selector i:before {
          content: ""; }
      @media (min-width: 768px) {
        #sticky-nav .container-fluid .row .selector {
          border: 0.2em solid white;
          background-color: #ff5100; } }
      @media (min-width: 992px) {
        #sticky-nav .container-fluid .row .selector .dropdown-trigger {
          transition: background-color 0.25s ease; }
          #sticky-nav .container-fluid .row .selector .dropdown-trigger:hover {
            background-color: #cc4100; } }
      @media (min-width: 1441px) {
        #sticky-nav .container-fluid .row .selector {
          max-width: 15%; } }
      #sticky-nav .container-fluid .row .selector.dropdown-open .dropdown-trigger,
      #sticky-nav .container-fluid .row .selector.dropdown-open .dropdown-trigger:hover {
        background-color: #363636; }
      #sticky-nav .container-fluid .row .selector .dropdown-container {
        z-index: 1;
        position: absolute;
        visibility: hidden;
        top: 100%;
        left: 0;
        width: 12em;
        background-color: #363636;
        text-transform: none;
        text-align: left; }
        @media (min-width: 576px) {
          #sticky-nav .container-fluid .row .selector .dropdown-container {
            top: 150%; }
            #sticky-nav .container-fluid .row .selector .dropdown-container:before {
              position: absolute;
              left: 1.8em;
              margin-left: -0.6em;
              border-width: 0.6em;
              border-style: solid;
              content: "";
              bottom: 100%;
              border-color: transparent transparent #363636 transparent; } }
        #sticky-nav .container-fluid .row .selector .dropdown-container li a {
          display: block;
          padding: 0.8em 0.7em;
          transition: background-color 0.25s ease;
          color: white;
          text-decoration: none; }
          #sticky-nav .container-fluid .row .selector .dropdown-container li a:hover {
            background-color: #1d1c1c; }
          #sticky-nav .container-fluid .row .selector .dropdown-container li a span {
            display: inline-block;
            opacity: 0.6;
            margin-right: 0.4em;
            width: 2.2em;
            font-weight: 500; }
        @media (max-width: 767.98px) {
          #sticky-nav .container-fluid .row .selector .dropdown-container li {
            font-size: 0.8em; } }
    #sticky-nav .container-fluid .row .trigger {
      position: relative; }
      #sticky-nav .container-fluid .row .trigger div {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 2.6em; }
        #sticky-nav .container-fluid .row .trigger div span {
          display: block;
          width: 100%;
          height: 0.3em;
          margin-bottom: 0.4em;
          background-color: white;
          border-radius: 0.15em;
          animation-duration: 0.5s;
          animation-fill-mode: both; }
          #sticky-nav .container-fluid .row .trigger div span:last-child {
            margin-bottom: 0; }
        #sticky-nav .container-fluid .row .trigger div.open span {
          animation-name: opensecond; }
          #sticky-nav .container-fluid .row .trigger div.open span:first-child {
            animation-name: openfirst; }
          #sticky-nav .container-fluid .row .trigger div.open span:last-child {
            animation-name: openthird; }
    #sticky-nav .container-fluid .row .search {
      background-color: white; }
      @media (max-width: 767.98px) {
        #sticky-nav .container-fluid .row .search {
          margin-top: 0.8em; } }
      #sticky-nav .container-fluid .row .search .field {
        padding: 0.8em 0.8em; }
        #sticky-nav .container-fluid .row .search .field input {
          width: 100%;
          border: none;
          border-radius: 0;
          font-family: "PxGrotesk";
          font-weight: 300;
          font-style: normal;
          font-size: 0.9em; }
          @media (max-width: 767.98px) {
            #sticky-nav .container-fluid .row .search .field input {
              font-size: 1em; } }
        #sticky-nav .container-fluid .row .search .field input::-webkit-input-placeholder {
          color: #7f7f7f; }
        #sticky-nav .container-fluid .row .search .field input::-moz-placeholder {
          color: #7f7f7f; }
        #sticky-nav .container-fluid .row .search .field input:-ms-input-placeholder {
          color: #7f7f7f; }
        #sticky-nav .container-fluid .row .search .field input:-moz-placeholder {
          color: #7f7f7f; }
        @media (min-width: 768px) {
          #sticky-nav .container-fluid .row .search .field {
            padding: 0.7em; } }
        @media (min-width: 992px) {
          #sticky-nav .container-fluid .row .search .field {
            padding: 0.75em 0.5em; } }
      #sticky-nav .container-fluid .row .search .button input {
        cursor: pointer;
        flex: 1;
        width: 100%;
        margin: 0;
        transition: background-color 0.25s ease;
        border: none;
        background-color: #363636;
        border-radius: 0;
        font-family: "PxGrotesk";
        font-weight: 300;
        font-style: normal;
        color: white;
        text-transform: uppercase; }
        @media (min-width: 768px) {
          #sticky-nav .container-fluid .row .search .button input {
            font-size: 1em; } }
        @media (min-width: 992px) {
          #sticky-nav .container-fluid .row .search .button input {
            font-size: 0.9em; } }
        @media (max-width: 767.98px) {
          #sticky-nav .container-fluid .row .search .button input {
            text-indent: -9999px; } }
        #sticky-nav .container-fluid .row .search .button input:hover {
          background-color: #504f4f; }
      @media (max-width: 767.98px) {
        #sticky-nav .container-fluid .row .search .button {
          position: relative; }
          #sticky-nav .container-fluid .row .search .button i {
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1.6em !important; }
            #sticky-nav .container-fluid .row .search .button i:before {
              content: ""; } }
      @media (min-width: 1441px) {
        #sticky-nav .container-fluid .row .search .button {
          max-width: 20%; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      #sticky-nav .container-fluid .row .phone {
        justify-content: flex-end; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      #sticky-nav .container-fluid .row .phone a {
        display: flex;
        margin-right: 1em;
        padding: 0.4em 0.8em 0.4em 0.75em;
        background-color: #ff5100;
        transition: color 0.25s ease;
        font-family: "PxGrotesk";
        font-weight: 500;
        font-style: normal;
        font-size: 1.3em;
        color: white;
        text-decoration: none; }
        #sticky-nav .container-fluid .row .phone a span {
          align-self: center; }
        #sticky-nav .container-fluid .row .phone a i {
          margin-right: 0.5em;
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
          #sticky-nav .container-fluid .row .phone a i:before {
            content: ""; }
        #sticky-nav .container-fluid .row .phone a:hover {
          background-color: #cc4100; } }
    #sticky-nav .container-fluid .row .menu ul {
      display: flex;
      align-items: center;
      list-style: none; }
      #sticky-nav .container-fluid .row .menu ul li {
        margin-right: 0.6em; }
        #sticky-nav .container-fluid .row .menu ul li:last-child {
          margin-right: 0; }
        #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-trigger {
          padding: 0.5em; }
        #sticky-nav .container-fluid .row .menu ul li.lang.dropdown-open .dropdown-trigger,
        #sticky-nav .container-fluid .row .menu ul li.lang.dropdown-open .dropdown-trigger:hover {
          color: #cccccc; }
        #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-container {
          z-index: 1;
          position: absolute;
          visibility: hidden;
          display: block;
          top: 115%;
          right: 0;
          width: 6em;
          background-color: #363636;
          text-transform: none;
          text-align: left; }
          #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-container:before {
            position: absolute;
            right: 0.7em;
            margin-left: -0.6em;
            border-width: 0.6em;
            border-style: solid;
            content: "";
            bottom: 100%;
            border-color: transparent transparent #363636 transparent; }
          #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-container li {
            display: block;
            margin-right: 0; }
            #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-container li a {
              display: block;
              padding: 0.8em 0.7em;
              transition: background-color 0.25s ease;
              color: white;
              text-decoration: none; }
              #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-container li a:hover {
                background-color: #1d1c1c; }
              #sticky-nav .container-fluid .row .menu ul li.lang .dropdown-container li a span {
                display: inline-block;
                opacity: 0.6;
                margin-right: 0.4em;
                width: 1.8em;
                font-weight: 500; }
    #sticky-nav .container-fluid .row .menu a {
      transition: color 0.25s ease;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      color: white;
      text-decoration: none; }
      @media (min-width: 992px) {
        #sticky-nav .container-fluid .row .menu a {
          font-size: 0.9em; } }
      #sticky-nav .container-fluid .row .menu a:not(.phone):hover {
        color: #cccccc; }
        #sticky-nav .container-fluid .row .menu a:not(.phone):hover .badge {
          color: white; }
      #sticky-nav .container-fluid .row .menu a .badge {
        display: inline-block;
        width: 1.4em;
        margin-left: 0.4em;
        padding: 0.25em;
        border-radius: 50%;
        background-color: #ff5100;
        color: white;
        font-size: 0.9em;
        text-align: center; }
      #sticky-nav .container-fluid .row .menu a i {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        #sticky-nav .container-fluid .row .menu a i:before {
          content: ""; }
      #sticky-nav .container-fluid .row .menu a.phone {
        display: block;
        padding: 0.4em 0.8em 0.3em 0.75em;
        transition: background-color 0.25s ease;
        background-color: #ff5100;
        font-weight: 500; }
        #sticky-nav .container-fluid .row .menu a.phone i {
          margin-right: 0.5em;
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
          #sticky-nav .container-fluid .row .menu a.phone i:before {
            content: ""; }
        @media (min-width: 768px) {
          #sticky-nav .container-fluid .row .menu a.phone {
            font-size: 1.3em; } }
        #sticky-nav .container-fluid .row .menu a.phone:hover {
          background-color: #cc4100; }
  #sticky-nav.full-width {
    position: static; }
    #sticky-nav.full-width .container-fluid {
      max-width: none; }
  #sticky-nav #mobile-menu {
    z-index: 1;
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 25%;
    background-color: #141414;
    text-align: center; }
    @media (min-width: 768px) {
      #sticky-nav #mobile-menu {
        position: absolute;
        top: 3.4em;
        left: auto;
        bottom: auto;
        width: 12em;
        padding: 1em;
        text-align: left;
        background-color: #363636; }
        #sticky-nav #mobile-menu:before {
          position: absolute;
          right: 0.8em;
          margin-left: -0.6em;
          border-width: 0.6em;
          border-style: solid;
          content: "";
          bottom: 100%;
          border-color: transparent transparent #363636 transparent; } }
    #sticky-nav #mobile-menu .grow {
      flex-direction: column; }
      #sticky-nav #mobile-menu .grow .col, #sticky-nav #mobile-menu .grow .container-fluid .row .search .field input, #sticky-nav .container-fluid .row .search .field #mobile-menu .grow input {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto; }
        #sticky-nav #mobile-menu .grow .col:last-child, #sticky-nav #mobile-menu .grow .container-fluid .row .search .field input:last-child, #sticky-nav .container-fluid .row .search .field #mobile-menu .grow input:last-child {
          flex-grow: 0; }
    #sticky-nav #mobile-menu ul {
      flex: 0 0 100%; }
      #sticky-nav #mobile-menu ul li {
        margin-bottom: 1.4em; }
        @media (min-width: 768px) {
          #sticky-nav #mobile-menu ul li {
            margin-bottom: 0.8em; }
            #sticky-nav #mobile-menu ul li:last-child {
              margin-bottom: 0; } }
        #sticky-nav #mobile-menu ul li a {
          transition: color 0.25s ease;
          font-family: "PxGrotesk";
          font-weight: 300;
          font-style: normal;
          font-size: 1.4em;
          color: white;
          text-decoration: none; }
          @media (min-width: 768px) {
            #sticky-nav #mobile-menu ul li a {
              font-size: 1.2em; } }
          #sticky-nav #mobile-menu ul li a:not([href^="tel:"]):hover {
            color: #cccccc; }
            #sticky-nav #mobile-menu ul li a:not([href^="tel:"]):hover .badge {
              color: white; }
          #sticky-nav #mobile-menu ul li a .badge {
            display: inline-block;
            width: 1.4em;
            margin-left: 0.4em;
            padding: 0.25em;
            border-radius: 50%;
            background-color: #ff5100;
            color: white;
            font-size: 0.9em;
            text-align: center; }
          #sticky-nav #mobile-menu ul li a i {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale; }
            #sticky-nav #mobile-menu ul li a i:before {
              content: ""; }
          #sticky-nav #mobile-menu ul li a[href^="tel:"] {
            display: block;
            padding: 0.4em 0.8em 0.3em;
            transition: background-color 0.25s ease;
            background-color: #ff5100;
            font-weight: 500; }
            #sticky-nav #mobile-menu ul li a[href^="tel:"]:hover {
              background-color: #cc4100; }
    #sticky-nav #mobile-menu .col.phone a, #sticky-nav #mobile-menu .container-fluid .row .search .field input.phone a, #sticky-nav .container-fluid .row .search .field #mobile-menu input.phone a {
      flex: 0 0 100%;
      padding: 0.6em 0 0.5em;
      transition: background-color 0.25s ease;
      background-color: #1ebea5;
      font-family: "PxGrotesk";
      font-weight: 500;
      font-style: normal;
      font-size: 2em;
      color: white;
      text-align: center;
      text-decoration: none; }
      #sticky-nav #mobile-menu .col.phone a i, #sticky-nav #mobile-menu .container-fluid .row .search .field input.phone a i, #sticky-nav .container-fluid .row .search .field #mobile-menu input.phone a i {
        margin-right: 0.5em;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        #sticky-nav #mobile-menu .col.phone a i:before, #sticky-nav #mobile-menu .container-fluid .row .search .field input.phone a i:before, #sticky-nav .container-fluid .row .search .field #mobile-menu input.phone a i:before {
          content: ""; }
      #sticky-nav #mobile-menu .col.phone a:hover, #sticky-nav #mobile-menu .container-fluid .row .search .field input.phone a:hover, #sticky-nav .container-fluid .row .search .field #mobile-menu input.phone a:hover {
        background-color: #1ba892; }

/**
 * Footer
 */
#footer {
  padding: 2.4em 0 0;
  background-color: #141414; }
  @media (min-width: 992px) {
    #footer {
      padding: 2.4em 0; } }
  #footer > div {
    padding-bottom: 2em; }
  #footer ul {
    list-style: none;
    font-family: "PxGrotesk";
    font-weight: 400;
    font-style: normal; }
    #footer ul.menu {
      margin-bottom: 1.8em;
      text-align: center;
      font-size: 1.1em; }
      @media (min-width: 768px) {
        #footer ul.menu {
          font-size: 1.2em; } }
      @media (min-width: 992px) {
        #footer ul.menu {
          font-size: 1em;
          text-align: left; } }
      #footer ul.menu li {
        margin-bottom: 1em; }
        #footer ul.menu li:last-child {
          margin-right: 0; }
        @media (min-width: 768px) {
          #footer ul.menu li {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 1.2em; } }
        @media (min-width: 992px) {
          #footer ul.menu li {
            display: block;
            margin-right: 0;
            margin-bottom: 1em; } }
        #footer ul.menu li a {
          font-family: "PxGrotesk";
          font-weight: 400;
          font-style: normal;
          color: white;
          text-decoration: none; }
    #footer ul.list li {
      margin-bottom: 0.6em; }
      #footer ul.list li a {
        transition: color 0.25s ease;
        font-family: "PxGrotesk";
        font-weight: 300;
        font-style: normal;
        color: #7f7f7f;
        text-decoration: none; }
        @media (min-width: 768px) {
          #footer ul.list li a {
            font-size: 0.85em; } }
        #footer ul.list li a:hover {
          color: #cccccc; }
  #footer a.show-more {
    color: #ff5100;
    text-decoration: none; }
    @media (min-width: 768px) {
      #footer a.show-more {
        font-size: 0.85em; } }
    #footer a.show-more i {
      margin-left: 0.25em;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      #footer a.show-more i:before {
        content: ""; }
    #footer a.show-more.less i:before {
      content: ""; }
  #footer h6 {
    font-family: "PxGrotesk";
    font-weight: 400;
    font-style: normal;
    color: white;
    text-decoration: none; }
    @media (min-width: 768px) {
      #footer h6 {
        font-size: 1em; } }
  #footer p {
    line-height: 1.4em;
    color: #cccccc;
    text-align: center;
    font-size: 0.95em; }
    @media (min-width: 768px) {
      #footer p {
        font-size: 1.05em; } }
    @media (min-width: 992px) {
      #footer p {
        font-size: 1em;
        text-align: left; } }
    #footer p a {
      transition: color 0.25s ease;
      color: #cccccc;
      text-decoration: none; }
      #footer p a:hover {
        color: #f7f7f7; }
  #footer .social {
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    font-size: 0.95em;
    color: #7f7f7f; }
    @media (min-width: 768px) {
      #footer .social {
        font-size: 0.85em; } }
    #footer .social [class^="col"] {
      margin-right: 1em; }
      #footer .social [class^="col"]:last-child {
        margin-right: 0; }
      #footer .social [class^="col"]:first-child {
        line-height: 1.5em; }
    #footer .social a {
      transition: color 0.25s ease;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1.6em;
      color: #7f7f7f;
      text-decoration: none; }
      #footer .social a:hover {
        color: #cccccc; }
      #footer .social a.facebook:before {
        content: ""; }
      #footer .social a.google:before {
        content: ""; }
      #footer .social a.twitter:before {
        content: ""; }
  #footer .copy {
    margin-top: 1.6em; }
    @media (min-width: 768px) {
      #footer .copy {
        margin-top: 1.8em; } }
    @media (min-width: 992px) {
      #footer .copy {
        margin-top: 2.4em; } }
    #footer .copy p {
      text-align: center;
      color: #7f7f7f;
      font-size: 0.85em; }
      @media (min-width: 992px) {
        #footer .copy p {
          text-align: left; } }
    #footer .copy a {
      transition: color 0.25s ease;
      color: #7f7f7f;
      text-decoration: none; }
      #footer .copy a:hover {
        color: #cccccc; }
